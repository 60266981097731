import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MaxWidth } from 'GlobalStyle';
import {
  ServiceInfo,
  ItemWrapper,
  ServiceInfoItem,
  ServiceContact,
  ServiceContactWrapper,
  ServiceContactItem,
  OnlineRequest,
  TextArea,
} from './style';
import Modal from './component/modal';
import PricePolicy from './component/pricePolicy';
import RequestForm from './component/requestForm';
import Download from './component/download';
import { useLocation } from 'react-router-dom';
import Confirm from './component/confirm';
import DownLoadConfirm from './component/confirm/DownLoadConfirm';

const Support = () => {
  const [pricePolicy, setPricePolicy] = useState(false);
  const [download, setDownload] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [downSucc, setDownSucc] = useState(false);
  const contentUsRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/customerSupport/contactUs') {
      const posY = contentUsRef?.current?.offsetTop;

      window.scrollTo({
        top: posY,
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  return (
    <>
      <ServiceInfo>
        <MaxWidth>
          <h2>
            <span>SECU E Cloud</span> 서비스 이용 안내
          </h2>
          <ItemWrapper>
            <ServiceInfoItem>
              <h5>
                <span>서비스 구성</span>
                <span className="describe">(메일 + 그룹웨어 + 보안)</span>
              </h5>
              <button onClick={() => setPricePolicy(!pricePolicy)}>
                <span>서비스 요금 정책</span>
              </button>
              {pricePolicy && (
                <Modal
                  closeModal={() => {
                    setPricePolicy(!pricePolicy);
                  }}
                >
                  <PricePolicy />
                </Modal>
              )}
            </ServiceInfoItem>
            <ServiceInfoItem>
              <h5>
                <span>기본제공 용량(단위)</span>
              </h5>
              <ul>
                <li>
                  메일 : <span>10GB </span>
                  <span className="unit">(1계정)</span>
                </li>
                <li>
                  메일 백업 : <span>50GB </span>
                  <span className="unit">(1기업)</span>
                </li>
                <li>
                  그룹웨어 : <span>100GB </span>
                  <span className="unit">(1기업)</span>
                </li>
              </ul>
            </ServiceInfoItem>
            <ServiceInfoItem>
              <h5>
                <span>SECU E Cloud 자료</span>
                <span className="describe">(제안서 / 브로슈어)</span>
              </h5>
              <button onClick={() => setDownload(!download)}>
                <span>다운로드 신청</span>
              </button>
              {download && (
                <Modal
                  type={'download'}
                  closeModal={() => {
                    setDownload(!download);
                  }}
                >
                  <Download setDownload={setDownload} setDownSucc={setDownSucc} />
                </Modal>
              )}
              {downSucc && <DownLoadConfirm setDownSucc={setDownSucc} />}
            </ServiceInfoItem>
          </ItemWrapper>
        </MaxWidth>
      </ServiceInfo>
      <ServiceContact>
        <MaxWidth>
          <h3>
            <span className="bold">SECU E Cloud</span>에 대해 궁금하신 점은&nbsp;
            <span className="block">부담 없이 문의해주세요.</span>
          </h3>
          <ServiceContactWrapper>
            <ServiceContactItem>
              <ul>
                <li>
                  전화문의<span>(평일 09:00 ~ 18:00)</span>
                  <span className="phoneNum">02.6012.7406</span>
                </li>
              </ul>
            </ServiceContactItem>
            <ServiceContactItem>
              <ul>
                <li>
                  제품문의
                  <span className="mail">119@egplatforms.com</span>
                </li>
                <li>
                  기술 지원 문의
                  <span className="mail">support@egplatforms.com</span>
                </li>
              </ul>
            </ServiceContactItem>
          </ServiceContactWrapper>
        </MaxWidth>
      </ServiceContact>
      <OnlineRequest ref={contentUsRef}>
        <MaxWidth>
          <h3>
            SECU E Cloud <span>온라인 문의하기</span>
            <span className="line">-</span>
            <p>
              <span>신속하고 정확한 상담을 위해 </span>
              <span>기업 및 담당자 정보를 </span>
              <span>입력해주세요.</span>
            </p>
          </h3>
          <RequestForm confirm={confirm} setConfirm={setConfirm} />
        </MaxWidth>
      </OnlineRequest>
      {confirm && (
        <Modal
          type={'confirm'}
          closeModal={() => {
            setConfirm(!confirm);
          }}
        >
          <Confirm setConfirm={setConfirm} />
        </Modal>
      )}
    </>
  );
};

export default Support;
