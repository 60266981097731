import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const DetailInner = styled.div`
  width: 1200px;
  margin: 0 auto;
  min-height: 50vh;
  @media screen and (max-width: 1300px) {
    width: auto;
    padding: 0 30px;
  }

  figure {
    width: 450px;
    margin: 0 0 75px 0;

    @media screen and (max-width: 800px) {
      width: auto;
    }
  }
`;

export const MenuH2 = styled.h2`
    font-size: 60px;
    font-weight: 500;
    font-family: 'SBAggrolL';
    margin: 50px 0 100px 0;
    color: #282828; 
    letter-spacing: -0.015em;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 80px;
      left:0;
      width: 360px;
      height: 220px;
      background: url('/images/background/bg-cs_circle01.png') no-repeat right center / contain;
      z-index: 0;

      @media screen and (max-width: 1300px) {
        display: none;
      }
    }

    @media screen and (max-width: 910px) {
      font-size: 40px;
      margin: 70px 0 70px 0;
      text-align: center;
    }
    @media screen and (max-width: 910px) {
      margin: 70px 0 70px 0;
    }
  }`;

export const DetailTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 100px 0;
  padding: 0 0 25px 0;
  border-bottom: 1px solid #b9b9b9;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }

  h3 {
    font-size: 30px;
    font-weight: 800;
    font-family: 'Pretendard';
    letter-spacing: -0.025em;

    @media screen and (max-width: 1150px) {
      margin: 0 0 30px 0;
      line-height: 1.3;
    }

    @media screen and (max-width: 800px) {
      font-size: 24px;
    }
  }
  span {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Pretendard';
    color: #999999;
  }
`;

export const DetailContent = styled.div`
  p {
    margin: 0 0 60px 0;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Pretendard';
    white-space: pre-wrap;
    letter-spacing: -0.025em;
    line-height: 1.45;
    text-align: center;  
      
    @media screen and (max-width: 800px) {
      font-size: 16px;
      font-weight: 400;
    }
    @media screen and (max-width: 400px) {
      font-size: 16px;
      margin: 0 0 40px 0;
    }
    * {
      font-family: 'Pretendard' !important;
    }
  }
  * {
    word-break: break-all;
  }
`;

export const DetailLink = styled.div`
  padding: 0 0 100px 0;
`;

export const Sourcetit = styled.span`
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Pretendard';
  margin: 0 5px 0 0;

  @media screen and (max-width: 800px) {
    font-size: 16px;
    margin: 0 0 15px 0;
  }
  @media screen and (max-width: 400px) {
    display: block;
    margin: 0 0 15px 0;
  }
`;

export const SourceLink = styled.span`
  font-size: 18px;
  font-weight: 400;
  font-family: 'Pretendard';

  @media screen and (max-width: 800px) {
    display: block;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  a {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Pretendard';
    color: #282828;

    @media screen and (max-width: 800px) {
      font-size: 16px;
    }
  }
`;
export const PageDirection = styled.div`
  padding: 0 0 90px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const NextPage = styled.div`
  display: flex;
  gap: 50px;
  padding: 20px 25px;
  background: #ffffff;
  width: auto;
  box-shadow: 0px 0px 10px 5px rgba(153, 153, 153, 0.1);
  border-radius: 10px;

  @media screen and (max-width: 800px) {
    padding: 15px 25px;
  }
  @media screen and (max-width: 600px) {
    gap: 15px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Pretendard';
  }

  .detailink {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Pretendard';
    width: 100%;
    letter-spacing: 0.01em;

    @media screen and (max-width: 550px) {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    a {
      display: block;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Pretendard';
      color: #282828;
    }
  }

  span {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Pretendard';
  }
`;
export const PrevPage = styled.div`
  display: flex;
  gap: 50px;
  padding: 20px 25px;
  background: #ffffff;
  width: auto;
  box-shadow: 0px 0px 10px 5px rgba(153, 153, 153, 0.1);
  border-radius: 10px;

  @media screen and (max-width: 800px) {
    padding: 15px 25px;
  }

  @media screen and (max-width: 600px) {
    gap: 15px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Pretendard';
  }

  .detailink {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Pretendard';
    width: 100%;

    @media screen and (max-width: 550px) {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    a {
      display: block;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Pretendard';
      color: #282828;
    }
  }

  span {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Pretendard';
  }
`;

export const BtnBox = styled.div`
  padding: 0 0 120px 0;
  text-align: center;

  @media screen and (max-width: 500px) {
    padding: 0 0 100px 0;
  }
`;

export const ListLink = styled(Link)`
  display: inline-block;
  padding: 15px 45px;
  background: #4d81db;
  border-radius: 30px;
  font-size: 18px;
  font-family: SBAggrolL;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #fffefe;

  @media screen and (max-width: 800px) {
    display: block;
    width: auto;
  }
`;

export const NLH2 = styled.h2`
  font-size: 24px;
  font-weight: 800;
  font-family: 'Pretendard';
  letter-spacing: -0.025;

  @media screen and (max-width: 1100px) {
    margin: 0 0 30px 0;
    line-height: 1.3;
  }

  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
`;

export const NLDetailTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 100px 0 100px 0;
  padding: 0 0 25px 0;
  border-bottom: 1px solid #b9b9b9;

  @media screen and (max-width: 1300px) {
    margin: 100px 0 60px 0;
  }
  @media screen and (max-width: 900px) {
    margin: 100px 0 40px 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 800;
    font-family: 'Pretendard';
    letter-spacing: -0.025;

    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }
  span {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Pretendard';
    color: #999999;
  }
`;

export const NLDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;  
  margin: 0 0 90px 0;
  min-height: 50vh;
  @media screen and (max-width: 400px) {
    margin: 0 0 70px 0;
  }
`;

export const ImgCus = styled.img``;
