import React, { useEffect, useState } from 'react';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  BtnBox,
  DetailInner,
  DetailTitle,
  ImgCus,
  ListLink,
  MenuH2,
  NextPage,
  NLDetailTitle,
  NLDiv,
  NLH2,
  PageDirection,
  PrevPage,
} from './style';
import axios from 'axios';
import trTags from '../../../hooks/useTrTag';
import TrDate from '../../../hooks/useTrDate';

const DetailPage = () => {
  const [detail, setDetail] = useState('');
  const { pathname } = useLocation();
  const idx = pathname.split('/')[4];

  useEffect(() => {
    async function getDetail() {
      try {
        const response = await axios.get(`/board/view?boardType=L&idx=${idx}`);
        setDetail(response.data.view);
      } catch (error) {}
    }
    getDetail();
  }, [pathname]);

  if (!detail) return false;
  return (
    <DetailInner>
      <MenuH2>뉴스레터</MenuH2>
      <NLDetailTitle>
        <h3>{detail.title}</h3>
        <span>{TrDate(detail.rgsde)}</span>
      </NLDetailTitle>

      <NLDiv dangerouslySetInnerHTML={{ __html: trTags(detail.contents) }}>
      </NLDiv>

      <PageDirection>
        <NextPage>
          <p>다음글</p>
          <span>
            <RiArrowUpSFill />
          </span>
          <span className="detailink">
            <Link
              to={`/news/newsletter/detail/${detail.nextIdx}`}
              style={{ pointerEvents: detail.nextIdx === 0 ? 'none' : '' }}
            >
              {detail.nextTitle === null ? '이전글이 없습니다.' : detail.nextTitle}
            </Link>
          </span>
        </NextPage>
        <PrevPage>
          <p>이전글</p>
          <span>
            <RiArrowDownSFill />
          </span>
          <span className="detailink">
            <Link
              to={`/news/newsletter/detail/${detail.prevIdx}`}
              style={{ pointerEvents: detail.prevIdx === 0 ? 'none' : '' }}
            >
              {detail.prevTitle === null ? '이전글이 없습니다.' : detail.prevTitle}
            </Link>
          </span>
        </PrevPage>
      </PageDirection>
      <BtnBox>
        <ListLink to="/news/newsletter">list</ListLink>
      </BtnBox>
    </DetailInner>
  );
};

export default DetailPage;
